
.secret {
    height: 100vh;
    background: url('../pictures/pyramid.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

.secret__p {
    padding-top: 20%;
    text-align: center;
    font-size: 40px;
    color: magenta;
    padding-bottom: 20%;
}

.secret__button-container { 
    text-align: center;
    padding: 30px;
}

.secret__button {
    background-color: black;
    color: white;
    width: auto;
    font-size: 25px;
    border: 1px solid magenta;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}

.secret__credit {
    position: absolute; 
    bottom: 75px;
    right: 5px;
    color: rgb(255, 255, 255);
    font-size: 12px;
}