
.about {
    background: url('../pictures/crystal-jene-yeo.jpg');
    background-size: cover;
    padding: 10px;
    font-size: 20px;
    min-width: 280px;
}

.about__headline { 
    text-align: center;
    margin-bottom: 5%;
    color: white;
    backdrop-filter: blur(5px);
    border-radius: 30px;
}

.about__container { 
    padding: 20px;
}

.about__columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.about__column {
    display: grid;
    max-width: 400px;
    height: auto;
    border: 1px dotted gray;
    margin-bottom: 60px;
    padding: 10px;
    cursor: default;
    border-radius: 10px;
    padding-bottom: 10px;
    position: relative;
    color: gray;
    backdrop-filter: blur(5px);
}

.about__column:hover{
    background-color: rgba(41, 87, 60, 0.6); 
    color: white;
}

ul {
    list-style: circle;
}

.about__plant__div {
    padding-bottom: 10%;
    text-align: center;
}

.about__plant__button {
    padding: 30px;
    font-size: 50px;
    width: 80%;
    border: none;
    background-color: rgb(134, 161, 134);
    color: white;
    border-radius: 50px;
    cursor: pointer;
}
@media (max-width: 768px) {
    .about__plant__button {
        font-size: 20px;
        padding: 20px;
    }
}
@media (max-width: 540px) {
    .about__plant__button {
        font-size: 15px;
        padding: 10px;
    }
}

.about__plant__button:hover {
    background-color: rgb(188, 202, 188);
}
