
.peace {
    /* position: relative;
    padding-bottom: 56.25%;
    padding-top: 25; */
    position: absolute; 
    width: 100%;
    height: 100%;
    margin-bottom: 0;
}

.peace__p {
    font-size: 15px;
}

.video__container {
    position: absolute; 
    top: 0;
    left: 0;
    /* z-index: -2; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    filter: brightness(100%);
}

.video__container:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: auto;
}

.video {
    /* position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%; */
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 56.25vw;
    min-width: 177.6vh;
    min-height: 100vh;
    transform: translate(-50%, -50%);
}

