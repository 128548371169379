
.rigamarole {
    height: 100vh;
    background: url('../pictures/bird-nonsense-mcgill-library.jpg');
    background-size: cover;
    padding: 20px;
    color: white;
}

.rigamarole__container {
    margin: 40px;
    padding: 20px;
}

.rigamarole__button-div {
    position: relative;
    top: 30%;
}

.rigamarole__peace-button {
    background-color: rgb(65, 26, 4);
    color: rgb(252, 241, 230);
    width: auto;
    font-size: 12px;
    border: none;
    border-radius: 90px;
    padding: 10px;
    cursor: pointer;
}