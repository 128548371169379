
.portfolio {
    background-size: contain;
    padding: 20px;
    color: white;
}

.portfolio__container { 
    backdrop-filter: blur(5px);
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid white;
    max-width: 1300px;
}

.portfolio__headline {
    text-align: center;
    margin-bottom: 20px;
}

.portfolio__intro {
    font-size: 20px;
    color: white;
    font-weight: bold;
    line-height: 30px;
    margin: 35px;
}
@media (max-width: 414px) {
    .portfolio__intro {
        font-size: 15px;
        margin: 20px;
    }
}

.portfolio__project-container {
    padding: 10px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.project { 
    width: 300px;
    max-width: 100%;
    color: white;
    display: grid;
    padding: 10px;
    margin: 10px;
    background-color: rgba(0, 0, 0, 0.4);
}
@media (min-width: 761px) and (max-width: 1101px) {
    .project {
        flex: 1 1 100%;
    }
}

.project__name {
    font-size: 30px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.project__image { 
    width: 90%;
    height: 96%;
    border-radius: 10px;
}

.project__best__div {
    /* padding: 20px; */
}

.project__best__image{
    width: 90%;
}

.project__link {
    text-decoration: none;
    color: white;
}

.project > p {
    padding: 7px;
    font-style: italic;
}
@media (max-width: 320px) {
    .project > p {
        display: none;
    }
}

.project > b {
    font-size: 18px;
}
@media (max-width: 320px) {
    .project > b {
        font-size: 12px;
    }
}

.project__icons {
    display: flex;
    justify-content: space-evenly;
    padding-top: 8px;
}

.project-youtube-icon, 
.project-github-icon {
    object-fit: contain;
    width: 45px;
}

.portfolio__pages__link {
    padding-right: 40px;
    padding-left: 40px;
    color: yellow;
    font-size: 30px;
    font-style: bold;
    padding-bottom: 10px;
    text-align: center;
    text-decoration: none;
}
@media (max-width: 540px){
    .portfolio__pages__link {
        font-size: 25px;
        padding-left: 25px;
        padding-right: 25px;
    }
}
@media (max-width: 320px) {
    .portfolio__pages__link {
        font-size: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
.portfolio__pages__link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.portfolio__image-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.portfolio__me4 {
    flex: 0 0 300px;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    align-self: flex-end;
    /* max-height: 100%; */
    /* max-width: 100%; */
}
@media (max-width: 936px) {
    .portfolio__me4{
        order: 2;
    }
}

.contact__form-div{
    flex: 0 0 300px;
    margin-top: 8%;
    padding-right: 20px;
    max-width: 100%;
}
@media (max-width: 936px) {
    .contact__form-div{
        order: 1;
    }
}

.contact__h {
    text-align: center;
    padding: 10px;
}

.contact__form {
    display: grid;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
}

.contact__message {
    resize: none;
    height: 100px;
}

.contact__input, .contact__message {
    width: 98%;
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background-color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif; 
}

/* submit button */
.pageclip-form__submit {
    background-color: rgba(255, 255, 255, 0.781);   
    color: green;
    width: 20%;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
}

.pageclip-form__submit:hover {
    background-color:  rgba(187, 222, 255, 0.842);
    color: black;
}
