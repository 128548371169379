.footer {
    background-color: black;
    border-top: 1px solid white;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.link__icons {
    display: flex;
    justify-content: center; 
    align-items: center;
}

.link__icons > a > img {
    margin-right: 15px;
    object-fit: contain;
}
@media (max-width: 540px) {
    .link__icons > a > img {
        width: 40px;
    }
}
@media (max-width: 320px) {
    .link__icons > a > img {
        width: 30px;
    }
}

.icon__resume {
    width: 60px;
}

.egg__image {
    object-fit: contain;
    width: 30px;
    margin-right: 15px;
}
@media (max-width: 540px) {
    .egg__img {
        display: none;
    }
}