
.main {
    background: url('../pictures/vines-runze-shi.jpg');
    background-size: contain;
    padding: 30px;
    padding-bottom: 80px;
    color: black;
}

.main__top-icon-div {
    display: flex;
    justify-content: flex-end;
}

.main__top-icon { 
    height: 80px;
    width: 80px;
    cursor: pointer;
}


