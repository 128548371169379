.header { 
    background-color: black;
    position: sticky;
    display: flex;
    font-size: 20px;
    padding: 20px;
    justify-content: space-between;
    /* top: 0; */
    z-index: 100;
    border-bottom: 1px solid white;
    color: white;
    /* width: 100%; */
}

.header__name {
    display: flex;
    align-items: center;
    cursor: pointer;
}
@media (max-width: 540px) {
    .header__name {
        font-size: 15px;
    }
}

.header__secret {
    width: 5px;
    margin-left: auto;
    margin-right: auto;
}

.header__secret:hover {
    background-color: white;
    cursor: pointer;
}

.header > ul {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.header__link {
    text-decoration: none;
    color: white;
    font-size: 20px;
    padding-right: 20px;
    cursor: pointer;
}
@media (max-width: 540px) {
    .header__link {
        padding-right: 10px;
    }
}

.header__main--active, 
.header__about--active, 
.header__other--active, 
.header__pages--active {
    color: rgb(255, 230, 0);
}

.header__inactive {
    color: white;
}

