
.plants {
    padding: 100px;
    background: url(../pictures/pothos.jpg);
    background-size: cover;
}
@media (max-width: 540px) {
    .plants {
        padding: 50px;
    }
}
@media (max-width: 360px) {
    .plants {
        padding: 20px;
    }
}

.plants__big__container {
    border: 1px solid black;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: rgba(255, 255, 255, 0.377);
    backdrop-filter: blur(8px);
}
@media (max-width: 540px) {
    .plants__big__container {
        padding: 10px;
    }
}

.plants__h {
    font-size: 40px;
    color: rgb(0, 0, 0);
    padding: 10px;
    letter-spacing: 5px;
}
@media (max-width: 540px) {
    .plants__h {
        font-size: 30px;
        padding: 5px;
    }
}
@media (max-width: 360px) {
    .plants__h {
        font-size: 20px;
        padding: 5px;
    }
}

.plants__container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.plant__name {
    background-color: rgb(53, 51, 51);
    color: white;
    padding: 3px;
    text-align: center;
    position: absolute;
    font-size: 20px;
}
@media (min-width: 360px) {
    .plant__name {
        font-size: 15px;
    }
}
@media (max-width: 360px) {
    .plant__name {
        font-size: 15px;
    }
}
@media (max-width: 280px) {
    .plant__name {
        font-size: 10px;
    }
}

.plant__card {
    width: 250px;
    height: 250px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px;
}
@media (max-width: 540px) {
    .plant__card {
        width: 200px;
        height: 200px;
    }
}
@media (max-width: 280px) {
    .plant__card {
        width: 150px;
        height: 150px;
    }
}

.plant__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
}
